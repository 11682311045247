<template>
    <div>
        <card class="p-5">
            <div class="row p-4">
                <h3 class="col-12 pb-3">
                    <b>PROFESIOGRAMA </b><br />
                </h3>
                <div class="col-6">
                    <label for="sucursal">Sucursal Profesiograma</label><br />
                    <b-form-select id="sucursal" value-field="id" @input="updateParent"
                        v-model="generalProfesiograma.sucursalId" text-field="descripcion" :options="listaSucursales"
                        required></b-form-select>
                </div>

                <div class="col-6">
                    <label for="sucursal">Versión Profesiograma</label><br />
                    <input v-model="generalProfesiograma.versionProfesiograma" placeholder="" type="text"
                        class="form-control " required />
                </div>

                <div class="col-6 py-2">
                    <div>
                        <label for="evaluador" class="form-label">Evaluador</label>
                        <b-form-select id="evaluador"
                            @input="getPersonaUbicacion(generalProfesiograma.personaResponsableId)"
                            v-model="generalProfesiograma.personaResponsableId" value-field="id" text-field="nombres"
                            :options="NombresPersonas" required>
                        </b-form-select>
                    </div>
                </div>
                <div class="col-6 py-2">
                    <div>
                        <label for="evaluadorCargo" class="form-label">Cargo Evaluador</label>
                        <input v-model="CargoNombre" placeholder="" type="text" class="form-control " disabled />
                    </div>
                </div>
                <div class="col-6 py-2">
                    <div>
                        <label for="fecha" class="form-label">Fecha Revisión</label>
                        <input v-model="generalProfesiograma.fechaVersion" placeholder="" type="date"
                            class="form-control " required />
                    </div>
                </div>

                <div class="col-6 py-2">
                    <div>
                        <label class="form-label">Licencia SO</label>
                        <input v-model="generalProfesiograma.licenciaSo" placeholder="" type="text"
                            class="form-control " required />
                    </div>
                </div>


            </div>
            <div class="col-12 py-2">
                <h3 class="">
                    <b>OBSERVACIONES GENERALES (CAMBIOS) </b><br />
                </h3>
                <div class="row-12 py-2">
                    <div>
                        <label for="evaluadorCargo" class="form-label">Observación</label>
                        <textarea v-model="generalProfesiograma.observaciones"
                            placeholder="Ingresa una observación sobre el profesiograma" type="textarea" id="anexos"
                            class="form-control " required />
                    </div>

                </div>
            </div>
        </card>
        <div class="row p-4">
            <card class=" col-6 p-5">
                <h3 class="">
                    <b>NOTAS </b><br />
                </h3>
                <div class="row-12 py-2" v-for="(notas) in agregarNota" :key="notas">
                    <div>
                        <label for="evaluadorCargo" class="form-label">Nota {{ notas }}:</label>
                        <textarea v-model="notasTotal[notas]" placeholder="Ingresa una nueva nota" type="textarea"
                            id="anexos" class="form-control " />
                    </div>

                </div>




                <div class="d-flex justify-content-start">
                    <vs-button @click="agregarItem('agregarNota')" type="button" success animation-type="scale"
                        size="large">
                        Agregar Nota
                        <template #animate> <i class="fas fa-plus"></i></template>
                    </vs-button>
                    <vs-button @click="removerItem('agregarNota', 'notasTotal')" type="button" animation-type="scale"
                        danger size="large">
                        Remover Nota
                        <template #animate> <i class="fas fa-minus"></i></template>
                    </vs-button>

                </div>


            </card>




            <card class="col-6 p-5">
                <h3 class="">
                    <b>ANEXOS </b><br />

                </h3>
                <div class="row-12 py-2">
                    <div v-for="(anexo) in agregarAnexo" :key="anexo">
                        <label for="evaluadorCargo" class="form-label">Anexo {{ anexo }}:</label>
                        <textarea v-model="anexos[anexo]" placeholder="Ingresa un nuevo anexo" type="textarea"
                            id="anexos" class="form-control " />


                    </div>
                </div>



                <div class="d-flex justify-content-start">
                    <vs-button @click="agregarItem('agregarAnexo')" type="button" class="vs-buttony-1"
                        animation-type="scale" success size="large">
                        Agregar Anexo
                        <template #animate> <i class="fas fa-plus"></i></template>
                    </vs-button>
                    <vs-button @click="removerItem('agregarAnexo', 'anexos')" type="button" class="vs-buttony-1"
                        animation-type="scale" danger size="large">
                        Remover Anexo
                        <template #animate> <i class="fas fa-minus"></i></template>
                    </vs-button>

                </div>
            </card>


        </div>



    </div>
</template>

<script>
const Swal = require("sweetalert2");

import { mapGetters } from "vuex";

export default {

    data() {
        return {

            listaSucursales: [],
            listaPersonas: [],
            listaPersonaUbicacion: [],

            CargoNombre: "",

            generalProfesiograma: {
                fechaVersion: null,
                personaResponsableId: null,
                cargoPersonaResponsableId: null,
                sucursalId: null,
                versionProfesiograma: null,
                licenciaSo: null,
                observaciones: null

            },
            notasTotal: [],
            anexos: [],
            agregarNota: 1,
            agregarAnexo: 1,
        }
    },

    async mounted() {
        this.$isLoading(true);

        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        //console.log("ussssssssssss", this.usuario);

        await this.getSucursales();
        await this.getPersonas();

        this.$isLoading(false);
    },

    methods: {

        updateParent() {
            this.$emit('update', {
                generalProfesiograma: this.generalProfesiograma,
                notasTotal: this.notasTotal,
                anexos: this.anexos
            });
        },

        agregarItem(item) {
            this[item]++;
        },

        //remover notar y anexos, tanto a nivel de arreglo como de contador
        removerItem(item, value) {

            if (this[item] <= 0) {
                Swal.fire({
                    title: "¡No hay elementos para eliminar!",
                    text: "Necesitas al menos un elemento para borrar",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "OK",
                })
            }
            else (

                this[item]--,
                //console.log(this[value]),
                this[value].pop()
            )


        },

        async getPersonas() {
            //console.log("usuario------>", this.usuario);
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "Persona/ListPersonaByEmpresa/" + this.usuario.empresa.id,
                });
                //console.log("EL ID DE LA EMPRESA ES:::::::::::::::", this.usuario.empresa.id);
                //console.log("get Personas... ", res);
                if (res.length > 0) {
                    this.listaPersonas = res;
                } else {
                    this.listaPersonas = [];
                }
            } catch (error) {
                this.listaPersonas = [];
                console.log("err", error);
            }
        },


        async getSucursales() {
            //console.log("Sucursales------>", this.usuario);
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
                });
                //console.log("get sucursales... ", res);
                if (res.length > 0) {
                    this.listaSucursales = res;
                } else {
                    this.listaSucursales = [];
                }
            } catch (error) {
                this.listaSucursales = [];
                console.log("err", error);
            }
        },

        async getPersonaUbicacion() {
            try {

                //console.log("get empresas...");
                let res = await this.$store.dispatch("hl_get", {
                    path: "PersonaUbicacion/GetByPersona/" + this.generalProfesiograma.personaResponsableId,
                });

                let cargo = res.cargo;
                //console.log("getPersonaUbicacion... ", cargo);
                if (cargo) {
                    this.generalProfesiograma.cargoPersonaResponsableId = cargo.id
                    this.CargoNombre = cargo.nombre
                    this.listaPersonaUbicacion = cargo;

                    //console.log("LISTADO DE CARGOS DE LA EMPRESempresasAAAAAAAAAAAAAAAA...", cargo.nombre);
                } else {
                    console.log("VACIO", this.listaPersonaUbicacion);
                    this.listaPersonaUbicacion = [];
                }
            } catch (error) {
                this.listaPersonaUbicacion = [];
                console.log("err", error);
            }
        },


    },
    computed: {
        ...mapGetters(["userLoggedIn"]),

        NombresPersonas() {

            //console.log(this.listaPersonas)
            return this.listaPersonas.map(persona => ({
                ...persona,
                id: persona.id,
                nombres: `${persona.nombres} ${persona.apellidos}`,

            }));
        },
    }
}
</script>