export const Categorias = ["A1", "A2", "A3", "O1", "O2", "O3", "O4"];

export const periodosExamanes = [
  "Semanal",
  "Mensual",
  "Trimestral",
  "Semestral",
  "Anual",
];

export const psicoExamanes = [
  "Valoración por psicología con entrevista.",
  "N/A"
];

export const examenesEspecializados = [
  
  "Optometría/Oftalmología",
  "Neumología",
  "Cardiología",
  "Neurología",
  "Endocrinología",
  "Gastroenterología",
  "Reumatología",
  "Infectología",
  "N/A"
];

export const examenesLaboratorio = [
  
  "Hemograma completo",
  "Perfil lipídico",
  "Glucosa en sangre",
  "Prueba de función hepática",
  "Prueba de función renal",
  "Electrolitos séricos",
  "Prueba de coagulación",
  "Examen de orina",
  "N/A"
];


export const factoresRiesgo = [
  {
    grupo: "Físicos",
    factores: [
      {
        factor: "Ruido excesivo",
        grupo: "Físicos",
      },
      {
        factor: "Vibraciones",
        grupo: "Físicos",
      },
      {
        factor: "Iluminación inadecuada",
        grupo: "Físicos",
      },
      {
        factor: "Radiaciones ionizantes",
        grupo: "Físicos",
      },
      {
        factor: "Radiaciones no ionizantes",
        grupo: "Físicos",
      },
      {
        factor: "Temperaturas extremas",
        grupo: "Físicos",
      },
      {
        factor: "Presión atmosférica",
        grupo: "Físicos",
      },
    ],
  },
  {
    grupo: "Químicos",
    factores: [
      {
        factor: "Sustancias tóxicas",
        grupo: "Químicos",
      },
      {
        factor: "Sustancias corrosivas",

        grupo: "Químicos",
      },
      {
        factor: "Sustancias inflamables y explosivas",
        grupo: "Químicos",
      },
      {
        factor: "Sustancias carcinogénicas",
        grupo: "Químicos",
      },
      {
        factor: "Sustancias mutagénicas y teratogénicas",
        grupo: "Químicos",
      },
    ],
  },
  {
    grupo: "Biológicos",
    factores: [
      {
        factor: "Microorganismos patógenos",
        grupo: "Biológicos",
      },
      {
        factor: "Exposición a fluidos corporales",
        grupo: "Biológicos",
      },
      {
        factor: "Vectores",
        grupo: "Biológicos",
      },
    ],
  },
  {
    grupo: "Ergonómicos",
    factores: [
      {
        factor: "Movimientos repetitivos",
        grupo: "Ergonómicos",
      },
      {
        factor: "Manipulación manual de cargas",
        grupo: "Ergonómicos",
      },
      {
        factor: "Posturas forzadas",
        grupo: "Ergonómicos",
      },
      {
        factor: "Diseño inadecuado del puesto de trabajo",
        grupo: "Ergonómicos",
      },
    ],
  },
  {
    grupo: "Psicosociales",
    factores: [
      {
        factor: "Estrés laboral",
        grupo: "Psicosociales",
      },
      {
        factor: "Acoso laboral (mobbing)",
        grupo: "Psicosociales",
      },
      {
        factor: "Cargas de trabajo excesivas",
        grupo: "Psicosociales",
      },
      {
        factor: "Falta de control sobre el trabajo",
        grupo: "Psicosociales",
      },
      {
        factor: "Conflictos interpersonales",
        grupo: "Psicosociales",
      },
    ],
  },
  {
    grupo: "Mecánicos",
    factores: [
      {
        factor: "Maquinaria sin protección",
        grupo: "Mecánicos",
      },
      {
        factor: "Herramientas mal utilizadas",
        grupo: "Mecánicos",
      },
      {
        factor: "Caídas desde altura",
        grupo: "Mecánicos",
      },
      {
        factor: "Golpes y choques",
        grupo: "Mecánicos",
      },
      {
        factor: "Objetos cortantes o punzantes",
        grupo: "Mecánicos",
      },
    ],
  },
  {
    grupo: "Seguridad",
    factores: [
      {
        factor: "Riesgo de incendios y explosiones",
        grupo: "Seguridad",
      },
      {
        factor: "Espacios confinados",
        grupo: "Seguridad",
      },
      {
        factor: "Inadecuada señalización de seguridad",
        grupo: "Seguridad",
      },
      {
        factor: "Fallas en equipos de protección individual (EPI)",
        grupo: "Seguridad",
      },
    ],
  },
];
