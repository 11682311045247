<template>
  <div>
    <b-container class="custom-container">
      <b-row>
        <b-col sm="12">

          <!-- INSPECCIONAR ELEMENTOS -->
          <profesiogramaForm />


        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import profesiogramaForm from "./Profesiograma/profesiogramaForm.vue";




export default {
  components: {
    profesiogramaForm,

  },
  name: "ProfesiogramaVer",
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),
  },
}
</script>
