<script>
export default {
  props: ["value"],

  data() {
    return {
      localChecked: this.value,
    };
  },
  watch: {
    localChecked(newVal) {
      this.$emit("input", newVal); // Emite el nuevo valor cuando cambia
    },
  },
};
</script>

<template>
  <label class="switchBtn">
    <input type="checkbox" v-model="localChecked" />
    <div class="slide round">{{ localChecked ? "Sí" : "No" }}</div>
  </label>
</template>

<style scoped>
.switchBtn {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}
.switchBtn input {
  display: none;
}
.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding: 8px;
  color: #fff;
}
.slide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 50px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slide {
  background-color: #284194;
  padding-left: 55px;
}
input:focus + .slide {
  box-shadow: 0 0 1px #000000;
}
input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -20px;
}
.slide.round {
  border-radius: 34px;
}
.slide.round:before {
  border-radius: 50%;
}
</style>
