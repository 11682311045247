<template>
    <div>
        <multiselect v-model="localSelected" :options="options" :multiple="true" :track-by="valorGuardar"
            placeholder="Selecciona opciones" :label="valorVer" :searchable="true" @input="updateParent" 
            :close-on-select="false" :clear-on-select="false" :preselect-first="selectFirst"
            :group-label="valorLabelGrupo"
            :group-values="valorGrupo"
            :group-select="true"
            class="style-chooser"
            />
            <span v-if="!localSelected.length" class="text-danger">Este campo es requerido.</span>
        <!-- <p>Seleccionado: {{ localSelectedNames }}</p>
        <p>Condicional: {{ allData }}</p> -->
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    components: { Multiselect },
    props: {
        selected: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        valorGuardar: {
            type: String,
            default: 'id'  // La propiedad única para identificar elementos
        },
        valorVer: {
            type: String,
            default: 'name'
        },
     

        selectFirst: {
            type: Boolean,
            default: true,
        },
        valorLabelGrupo: {
            type: String,
            default: 'id' 
        },
        valorGrupo: {
            type: String,
            default: 'name'
        },
    },
    data() {
        return {
            localSelected: this.selected
        };
    },
    computed: {
        // Computa una lista con solo los nombres de los elementos seleccionados

        localSelectedNames() {
       
        
                return this.localSelected;
            
        }
    },
    watch: {
        selected(newVal) {
            this.localSelected = newVal;
        },
        // Sincroniza localSelected cuando las opciones cambian
        options() {
            this.localSelected = [];
            this.updateParent();
        }
    },

    // AJUSTAR DE ACUERDO A LA INFORMACIÓN QUE SE REQUIERA EMITIR
    methods: {
        updateParent() {

            this.$emit('input', this.localSelectedNames);
        }
    }
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>