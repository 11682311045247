<style>
/* Animaciones personalizadas */
.fade-enter-active,
.fade-leave-active {
    transition: transform 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    transform: translateY(-10%);
}

.fade-leave,
.fade-enter-to

/* .fade-enter-active in <2.1.8 */
    {
    transform: translateY(0);
}
</style>

<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
                <form @submit.prevent="saveDataProfesiograma()">

                    <principalProfesiograma @update="valuePrincipalProfesiograma" />
                    <!-- <pre>{{ datosRecibidos }}</pre> -->
                    <div v-for="(form, index) in cardsCategorias" :key="index" style="margin-bottom: 20px;">
                        <card class=" p-5">
                            <div class="d-flex flex-row justify-content-between align-items-center border-bottom h-25 mb-5"
                                style="cursor: pointer;" @click="toggleCollapse(index)">
                                <h3>
                                    <b>CATEGORIA {{ index + 1 }} </b><br />
                                    <p>TIPO CATEGORIA <b>{{ categoria[index] }}</b></p>
                                </h3>
                                <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
                                <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
                            </div>
                            <transition name="fade">
                                <div v-show="!isCollapsed[index]" class=" mt-5">
                                    <div class="form-row mb-5 px-5">
                                        <div class="form-group col-md-2">
                                            <h5 for="Codigo"><b>CATEGORIA:</b></h5>
                                            <b-select type="text" class="form-control bg-dark personalizado"
                                                v-model="categoria[index]" :options="Categorias" required />
                                        </div>
                                        <div class="form-group col-md-3">
                                            <h5><b>Periodo Exámenes</b></h5>
                                            <b-select type="text" class="form-control bg-dark personalizado"
                                                :options="periodosExamanes" v-model="periodoExamen[index]" required />
                                        </div>
                                        <div class="form-group col-md-2">
                                            <h5 for="Ubicacion"><b>Nivel Cargo:</b></h5>
                                            <b-select type="text" class="form-control bg-dark personalizado"
                                                @change="handleNivelCargoChange(index)" :options="cargosNivel"
                                                value-field="id" text-field="descripcion"
                                                v-model.lazy="NivelCargo[index]" required />
                                        </div>
                                        <div class="form-group col-md-5">
                                            <h5 for="Tipo"><b>Cargos Involucrados</b></h5>
                                            <multiSelect v-if="!isLoading[index]" v-model="cargosInvolucrados[index]"
                                                :options="CargosPorNivel[index]" valorGuardar="id" valorVer="nombre"
                                                :selectFirst=false :allData="false" />
                                            <p v-else>Cargando...</p>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <h5 for="Capacidad"><b>Peligros Expuestos:</b></h5>
                                            <GroupMultiSelect v-model="peligrosExpuestos[index]"
                                                :options="factoresRiesgo" valorGuardar="factor" valorVer="factor"
                                                :selectFirst=false valorLabelGrupo="grupo" valorGrupo="factores" />
                                        </div>
                                    </div>
                                    <!-- Resto del formulario -->
                                    <transition name="fade">
                                        <div class="form-col">
                                            <h2 class="border-bottom mb-3" @click="toggleCollapse(form + 1)">EXAMENES
                                            </h2>
                                            <div v-show="!isCollapsed[form + 1]" class="form-row">
                                                <div class="form-group col-6 p-4">
                                                    <h5 class="border-bottom col-12 mb-2">
                                                        <b>EXAMEN MÉDICO OCUPACIONAL CON ÉNFASIS
                                                            OSTEOMUSCULAR
                                                        </b>
                                                    </h5>
                                                    <div class=" d-flex flex-row col-16 justify-content-around m-4">
                                                        <div class="d-flex flex-column">
                                                            <label> INGRESO/ PREOCUP. </label>
                                                            <CheckButton v-model="aplicaIngreso[index]" />
                                                        </div>
                                                        <div class="d-flex flex-column">
                                                            <label> PERIÓDICO </label>
                                                            <CheckButton v-model="aplicaPeriodico[index]" />
                                                        </div>
                                                        <div class="d-flex flex-column">
                                                            <label> RETIRO </label>
                                                            <CheckButton v-model="aplicaRetiro[index]" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-6 p-4">
                                                    <h5 class="border-bottom col-12 mb-2">
                                                        <b>EVALUACIÓN PSICOLÓGICA
                                                        </b>
                                                    </h5>
                                                    <div class="">
                                                        <div class="col-12">
                                                            <b-select type="text"
                                                                class="form-control bg-dark personalizado"
                                                                v-model="psicoExamen[index]" :options="psicoExamanes"
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-6 p-4">
                                                    <h5 class=" mt-2 border-bottom col-12 ">
                                                        <b>PRUEBAS PARACLÍNICAS (INGRESO)
                                                        </b>
                                                    </h5>
                                                    <div class="form-row col-12 mt-4">
                                                        <div class="col-6">
                                                            <label> PRUEBAS ESPECIALIZADAS </label>
                                                            <div>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesEspecializadosIngreso[index]"
                                                                    :options="examenesEspecializados"
                                                                    valorGuardar="title" valorVer="title"
                                                                    :allData="false" :selectFirst=false />
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <label> EXÁMENES DE LABORATORIO </label>
                                                            <div>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesLaboratorioIngreso[index]"
                                                                    :options="examenesLaboratorio" :selectFirst=false />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-6 p-4">
                                                    <h5 class=" mt-2 border-bottom col-12">
                                                        <b>PRUEBAS PARACLÍNICAS (PERIÓDICO Y RETIRO)
                                                        </b>
                                                    </h5>
                                                    <div class="form-row  mt-4">
                                                        <div class=" col-6">
                                                            <h4 class=" text-center mb-2"><b> PERIÓDICO</b> </h4>
                                                            <div class="d-flex flex-column">
                                                                <label> PRUEBAS ESPECIALIZADAS </label>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesEspecializadosPeriodico[index]"
                                                                    :options="examenesEspecializados"
                                                                    :selectFirst=false />
                                                            </div>
                                                            <div class="d-flex flex-column">
                                                                <label> EXÁMENES DE LABORATORIO </label>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesLaboratorioPeriodico[index]"
                                                                    :options="examenesLaboratorio" :selectFirst=false />
                                                            </div>
                                                        </div>
                                                        <div class=" col-6 ">
                                                            <h4 class=" text-center mb-2"><b> RETIRO</b> </h4>
                                                            <div>
                                                                <label> PRUEBAS ESPECIALIZADAS </label>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesEspecializadosRetiro[index]"
                                                                    :options="examenesEspecializados"
                                                                    :selectFirst=false />
                                                            </div>
                                                            <div>
                                                                <label> EXÁMENES DE LABORATORIO </label>
                                                                <ArrayMultiSelect
                                                                    v-model="examenesLaboratorioRetiro[index]"
                                                                    :options="examenesLaboratorio" :selectFirst=false
                                                                    required />
                                                                <!-- <p>{{ examenesLaboratorioRetiro[index] }}</p> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12 p-4">
                                                    <h5 class="border-bottom col-12"><b>ANEXOS</b></h5>
                                                    <ArrayMultiSelect v-model="anexosProfesiograma[index]"
                                                        :options="anexosConNA" />
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </transition>
                        </card>
                    </div>
                    <div class="d-flex justify-content-start">
                        <vs-button @click="agregarFormulario" type="button" class="py-1" animation-type="vertical"
                            success size="large">
                            Agregar Categoria
                            <template #animate> <i class="fas fa-plus"></i></template>
                        </vs-button>
                        <vs-button @click="removerFormulario" type="button" class="py-1" animation-type="vertical"
                            danger size="large">
                            Remover Categoria
                            <template #animate> <i class="fas fa-plus"></i></template>
                        </vs-button>
                    </div>
                    <div class=" d-flex justify-content-center">
                        <button type="submit"
                            class="btn btn-primary vs-button vs-button--null vs-button--size-null vs-button--animate vs-button--success vs-button--gradient"
                            style="font-size: 15px; width: 15%; height: 50px;"><i class="fas fa-plus mr-1"></i>
                            Completar
                            Registro</button>
                    </div>
                </form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
const Swal = require("sweetalert2");

import { Categorias } from "./profesiogramaData";
import { periodosExamanes } from "./profesiogramaData";
import { psicoExamanes } from "./profesiogramaData";
import { examenesEspecializados } from "./profesiogramaData";
import { factoresRiesgo } from "./profesiogramaData";
import { examenesLaboratorio } from "./profesiogramaData";

import multiSelect from "../../../../components/MultiSelect/MultiSelect.vue";
import ArrayMultiSelect from "../../../../components/MultiSelect/ArrayMultiSelect.vue";
import GroupMultiSelect from "../../../../components/MultiSelect/GroupMultiSelect.vue";
import peligrosYRiesgosData from "../../PeligrosYRiesgos/peligrosYRiesgosData";
import CheckButton from "../../../../components/buttons/CheckButton";

import principalProfesiograma from "./principalProfesiograma.vue";

export default {

    components: {
        multiSelect,
        CheckButton,
        GroupMultiSelect,
        principalProfesiograma,
        ArrayMultiSelect

    },
    data() {
        return {
            usuario: {},
            peligrosYRiesgosData,
            Categorias,
            periodosExamanes,
            factoresRiesgo,
            psicoExamanes,
            examenesEspecializados,
            examenesLaboratorio,

            isCollapsed: {

            },

            datosRecibidos: {
                generalProfesiograma: {},
                notasTotal: [],
                anexos: []
            },

            cardsCategorias: 1,
            isLoading: {},

            cargosNivel: [],
            CargosPorNivel: [],
            categoria: [],
            NivelCargo: [],
            cargosInvolucrados: [],
            peligrosExpuestos: [],
            periodoExamen: [],
            aplicaIngreso: [],
            aplicaPeriodico: [],
            aplicaRetiro: [],
            psicoExamen: [],
            examenesEspecializadosIngreso: [],
            examenesLaboratorioIngreso: [],
            examenesEspecializadosRetiro: [],
            examenesLaboratorioRetiro: [],
            examenesEspecializadosPeriodico: [],
            examenesLaboratorioPeriodico: [],
            anexosProfesiograma: [],





        }
    },
    created() {

        // CREAR Indices para los valores default del button
        const totalIndices = 15;

        // Inicializar cada arreglo con el valor false para cada índice
        for (let i = 0; i < totalIndices; i++) {
            this.aplicaIngreso[i] = false;
            this.aplicaPeriodico[i] = false;
            this.aplicaRetiro[i] = false;
        }
    },

    async mounted() {


        this.$isLoading(true);

        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));



        await this.getNivelCargos();

        this.$isLoading(false);

    },

    methods: {

        valuePrincipalProfesiograma(value) {
            this.datosRecibidos = value;
        },


        toggleCollapse(colapsar) {
            this.$set(this.isCollapsed, colapsar, !this.isCollapsed[colapsar]);
        },

        agregarFormulario() {
            this.cardsCategorias++;
        },
        removerFormulario() {

            Swal.fire({
                title: "¿Deseas eliminar esta categoria?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Eliminar categoria",
                confirmButtonColor: "#d33",
                cancelButtonText: "Cancelar"
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if (this.cardsCategorias == 1) {  // Evita que se borre el último formulario
                        Swal.fire({
                            title: "¡Necesitas Categorias!",
                            text: "Necesitas al menos una Categoria para el profesiograma",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "OK",
                        })
                    }
                    else {
                        this.cardsCategorias--;
                    }
                } else if (result.isDenied) {
                    Swal.fire("No hemos borrado la categoria", "", "info");
                }
            });

        },

        async getNivelCargos() {

            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "TipoCargo/TiposDeCargo",
                });
                //console.log("LOS TIPOS DE CARGO SON:", res)
                this.cargosNivel = res
            }
            catch (error) {
                console.log("err", error);
            }
        },

        async handleNivelCargoChange(index) {
            this.$set(this.isLoading, index, true);
            await this.getCargosPorNivel(index);
            this.$set(this.isLoading, index, false);
        },

        async getCargosPorNivel(index) {
            if (!Array.isArray(this.CargosPorNivel)) {
                this.$set(this, 'CargosPorNivel', []);
            }

            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "Cargo/TodosLosCargosPorEmpresaYTipoCargo/" + this.usuario.empresa.id + "/" + this.NivelCargo[index],
                });
                //console.log("LOS CARGOS POR ESE TIPO SON:", res);
                // Actualizar solo el índice específico
                this.$set(this.CargosPorNivel, index, res);

                // Limpiar solo el cargo involucrado correspondiente
                this.$set(this.cargosInvolucrados, index, []);

                //console.log("LOS CARGOS POR ESE NIVEL:", this.CargosPorNivel[index]);
            } catch (error) {
                console.log("err", error);
            }
        },


        // --------------------------------------------------------------- ALISTAR DATA PARA REGISTRO PRINCIPAL PROFESIOGRAMA --------------------------------------------------------//

        async saveDataProfesiograma() {
            // Validar todos los campos primero
            let hasEmptyFields = false;

            for (let i = 0; i < this.categoria.length; i++) {
                if (
                    (!this.cargosInvolucrados[i] || this.cargosInvolucrados[i].length === 0) ||
                    (!this.peligrosExpuestos[i] || this.peligrosExpuestos[i].length === 0) ||
                    (!this.examenesEspecializadosIngreso[i] || this.examenesEspecializadosIngreso[i].length === 0) ||
                    (!this.examenesLaboratorioIngreso[i] || this.examenesLaboratorioIngreso[i].length === 0) ||
                    (!this.examenesEspecializadosRetiro[i] || this.examenesEspecializadosRetiro[i].length === 0) ||
                    (!this.examenesLaboratorioRetiro[i] || this.examenesLaboratorioRetiro[i].length === 0) ||
                    (!this.examenesEspecializadosPeriodico[i] || this.examenesEspecializadosPeriodico[i].length === 0) ||
                    (!this.examenesLaboratorioPeriodico[i] || this.examenesLaboratorioPeriodico[i].length === 0) ||
                    (!this.anexosProfesiograma[i] || this.anexosProfesiograma[i].length === 0)
                ) {
                    hasEmptyFields = true;
                    break;  // No necesitamos seguir revisando si ya hemos encontrado un campo vacío
                }
            }

            // Si hay campos vacíos, muestra la alerta y detén el proceso
            if (hasEmptyFields) {
                Swal.fire({
                    title: "¡No Hemos Registrado, faltan campos!",
                    text: "Por favor revisa la información ingresada, campos requeridos",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "OK",
                });
                return;  // Detén la ejecución de la función
            }

            // Si todas las validaciones pasan, procede a registrar `dataPrincipalProfesiograma`
            this.datosRecibidos.notasTotal.shift();

            let notasArray = this.datosRecibidos.notasTotal;
            let anexosArray = this.datosRecibidos.anexos;

            let notasJson = JSON.stringify(notasArray);
            let anexosJson = JSON.stringify(anexosArray);

            Object.assign(this.datosRecibidos.generalProfesiograma, {
                notas: notasJson,
                anexos: anexosJson,
            });

            let dataPrincipalProfesiograma = this.datosRecibidos.generalProfesiograma;
            let resPrincipal = null;

            try {
                resPrincipal = await this.$store.dispatch("hl_post", {
                    path: "SstProfesiograma/CreateSstProfesiograma/",
                    data: dataPrincipalProfesiograma
                });

                let idPrincipal = resPrincipal.id;

                // Ahora, registra todos los items `dataProfesiograma` asociados
                for (let i = 0; i < this.categoria.length; i++) {
                    let dataProfesiograma = {
                        categoria: this.categoria[i],
                        nivelCargo: this.NivelCargo[i],
                        cargoVinculacionId: JSON.stringify(this.cargosInvolucrados[i]),
                        peligros: JSON.stringify(this.peligrosExpuestos[i]),
                        periodicidadExamen: this.periodoExamen[i],
                        examenOsteomuscularIngreso: this.aplicaIngreso[i] ? "Aplica" : "No aplica",
                        examenOsteomuscularPeriodico: this.aplicaPeriodico[i] ? "Aplica" : "No aplica",
                        examenOsteomuscularRetiro: this.aplicaRetiro[i] ? "Aplica" : "No aplica",
                        evaluacionPsicologicaIngreso: this.psicoExamen[i],
                        pruebasParaclinicasIngresoEspecializadas: JSON.stringify(this.examenesEspecializadosIngreso[i]),
                        pruebasParaclinicasIngresoExamenes: JSON.stringify(this.examenesLaboratorioIngreso[i]),
                        pruebasParaclinicasPeriodicoEspecializadas: JSON.stringify(this.examenesEspecializadosPeriodico[i]),
                        pruebasParaclinicasPeriodicoExamenes: JSON.stringify(this.examenesLaboratorioPeriodico[i]),
                        pruebasParaclinicasRetiroEspecializadas: JSON.stringify(this.examenesEspecializadosRetiro[i]),
                        pruebasParaclinicasRetiroExamenes: JSON.stringify(this.examenesLaboratorioRetiro[i]),
                        anexosQueAplican: JSON.stringify(this.anexosProfesiograma[i]),
                        sstProfesiogramaId: idPrincipal
                    };

                    try {
                        let resProfesiograma = await this.$store.dispatch("hl_post", {
                            path: "SstProfesiograma/CreateSstProfesiogramaItems/",
                            data: dataProfesiograma
                        });

                        if (resProfesiograma) {
                            Swal.fire({
                                title: "Listo!",
                                text: "Registro almacenado correctamente",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "OK",
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    this.$router.push("/helex/SGSST/ListarProfesiograma");
                                }
                            });
                        }
                    } catch (error) {
                        console.log("err", error);
                        Swal.fire({
                            title: "¡No Hemos Registrado!",
                            text: "Por favor revisa la información ingresada, si el problema persiste, contacta con el soporte técnico. Código de error: " + error.message,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "OK",
                        });
                    }
                }
            } catch (error) {
                console.log("err", error);
                Swal.fire({
                    title: "¡No Hemos Registrado!",
                    text: "Por favor revisa la información ingresada, si el problema persiste, contacta con el soporte técnico. Código de error: " + error.message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "OK",
                });
            }
        }
    },


    computed: {
            anexosConNA() {
                // Crear una copia del arreglo para no modificar el original
                let anexosModificados = [...this.datosRecibidos.anexos];

                // Si el arreglo tiene elementos, cambiar el primer elemento a "N/A"
                if (anexosModificados.length > 0) {
                    anexosModificados[0] = "N/A";
                } else {
                    // Si está vacío, simplemente agregar "N/A"
                    anexosModificados.push("N/A");
                }

                return anexosModificados;
            }
        }

    }
</script>