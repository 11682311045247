<template>
    <div>
        <multiselect v-model="localSelected" :options="options" :multiple="true" 
            placeholder="Selecciona opciones" :searchable="true" @input="updateParent" 
            :close-on-select="false" :clear-on-select="false" :preselect-first="selectFirst"
            class="style-chooser"
            :show-labels="false" />
            <span v-if="!localSelected.length" class="text-danger">Este campo es requerido.</span>
        <!-- <p>Seleccionado: {{ localSelectedNames }}</p>
        <p>Condicional: {{ allData }}</p> -->
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    components: { Multiselect },
    props: {
        selected: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        
 

        selectFirst: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            localSelected: this.selected
        };
    },

    watch: {
        selected(newVal) {
            this.localSelected = newVal;
        },
        // Sincroniza localSelected cuando las opciones cambian
        options() {
            this.localSelected = [];
            this.updateParent();
        }
    },

    // AJUSTAR DE ACUERDO A LA INFORMACIÓN QUE SE REQUIERA EMITIR
    methods: {
        updateParent() {

            this.$emit('input', this.localSelected);
        }
    }
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/* Estilos personalizados */

</style>